import Layout from "../../components/common/Layout"
import ArrowZ from "../../components/common/ArrowZ"
import CareersLink from "../../components/common/CareersLink"
import Footer from "../../components/common/Footer"
import React from "react"
import Kv from "../../components/section/media/Kv"
import Main from "../../components/section/media/Main"

export default function Media() {
  let title = "MEDIA"
  let description = "CRISPのメディア情報はこちら"
  let headTtl = "MEDIA"

  return (
    <Layout title={title} description={description}>
      <div id="page">
        <div id="media" className="js-page">
          <main id="main">
            <div id="contents" className="contents">
              <Kv headTtl={headTtl} />
              <Main />

              <CareersLink />
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </Layout>
  )
}
