import React from "react"

const Kv = ({ headTtl }) => {
  return (
    <section className="p-common-kv p-media-kv">
      <div className="p-common-kv__wrapper">
        <div className="p-common-kv__head">
          <h1 className="p-common-kv__head-ttl">{headTtl}</h1>
        </div>
      </div>
    </section>
  )
}

export default Kv
