import React from "react"
import ArrowZ from "../../common/ArrowZ"
import Img from "../../common/Img"

let mainMedias = [
  {
    link: "https://www.youtube.com/watch?v=jCuwyoGHTG8",
    src: "/asset/img/media/media_thumb_06.jpg",
    src2x: "/asset/img/media/media_thumb_06@2x.jpg",
    srcWebp: "/asset/img/media/media_thumb_06.jpg.webp",
    srcWebp2x: "/asset/img/media/media_thumb_06@2x.jpg.webp",
    alt: "【ホリエモン×クリスプ宮野 1/3】小規模飲食店がコンビニに勝つ方法",
    ttl: "HORIE ONE",
    txt: "【ホリエモン×クリスプ宮野 1/3】小規模飲食店がコンビニに勝つ方法",
  },
  {
    link: "https://www.youtube.com/watch?v=ZdWN9hNQf0o",
    src: "/asset/img/media/media_thumb_07.jpg",
    src2x: "/asset/img/media/media_thumb_07@2x.jpg",
    srcWebp: "/asset/img/media/media_thumb_07.jpg.webp",
    srcWebp2x: "/asset/img/media/media_thumb_07@2x.jpg.webp",
    alt: "【ホリエモン×クリスプ宮野 2/3】外食産業はルイ・ヴィトンの考え方を取り入れろ！市場拡大の鍵はブランド化とエンタメ化",
    ttl: "HORIE ONE",
    txt: "【ホリエモン×クリスプ宮野 2/3】外食産業はルイ・ヴィトンの考え方を取り入れろ！市場拡大の鍵はブランド化とエンタメ化",
  },
  {
    link: "https://www.youtube.com/watch?v=MCOFOxWFeKA",
    src: "/asset/img/media/media_thumb_08.jpg",
    src2x: "/asset/img/media/media_thumb_08@2x.jpg",
    srcWebp: "/asset/img/media/media_thumb_08.jpg.webp",
    srcWebp2x: "/asset/img/media/media_thumb_08@2x.jpg.webp",
    alt: "【ホリエモン×クリスプ宮野 3/3】コンビニは〇〇が苦手？飲食店は対コンビニ戦略を考えろ！",
    ttl: "HORIE ONE",
    txt: "【ホリエモン×クリスプ宮野 3/3】コンビニは〇〇が苦手？飲食店は対コンビニ戦略を考えろ！",
  },
  {
    link: "https://toyokeizai.net/articles/-/406689",
    src: "/asset/img/media/media_thumb_05.jpg",
    src2x: "/asset/img/media/media_thumb_05@2x.jpg",
    srcWebp: "/asset/img/media/media_thumb_05.jpg.webp",
    srcWebp2x: "/asset/img/media/media_thumb_05@2x.jpg.webp",
    alt: "1000円超の｢サラダ専門店｣に男性も通う理由",
    ttl: "東洋経済 ONLINE",
    txt: "1000円超の｢サラダ専門店｣に男性も通う理由",
  },
  {
    link: "https://newspicks.com/news/5038112/body/",
    src: "/asset/img/media/media_thumb_04.jpg",
    src2x: "/asset/img/media/media_thumb_04@2x.jpg",
    srcWebp: "/asset/img/media/media_thumb_04.jpg.webp",
    srcWebp2x: "/asset/img/media/media_thumb_04@2x.jpg.webp",
    alt: "【クリスプ 社長】サラダの既成概念を変えた男の破天荒な人生",
    ttl: "NEWS PICKS",
    txt: "【クリスプ 社長】サラダの既成概念を変えた男の破天荒な人生",
  },
  {
    link: "https://forbesjapan.com/articles/detail/33746",
    src: "/asset/img/media/media_thumb_03.jpg",
    src2x: "/asset/img/media/media_thumb_03@2x.jpg",
    srcWebp: "/asset/img/media/media_thumb_03.jpg.webp",
    srcWebp2x: "/asset/img/media/media_thumb_03@2x.jpg.webp",
    alt: "店を閉めて医療従事者に無償提供。クリスプサラダワークスが作る「善意の輪」",
    ttl: "Forbes JAPAN",
    txt: "店を閉めて医療従事者に無償提供。クリスプサラダワークスが作る「善意の輪」",
  },
  {
    link: "https://exp-d.com/interview/4152/",
    src: "/asset/img/media/media_thumb_02.jpg",
    src2x: "/asset/img/media/media_thumb_02@2x.jpg",
    srcWebp: "/asset/img/media/media_thumb_02.jpg.webp",
    srcWebp2x: "/asset/img/media/media_thumb_02@2x.jpg.webp",
    alt: "「接客から機械的な会話をなくしたい」ーークリスプ・サラダワークスが店舗のデジタルシフトを進める理由",
    ttl: "XD（クロスディー）",
    txt: "「接客から機械的な会話をなくしたい」ーークリスプ・サラダワークスが店舗のデジタルシフトを進める理由",
  },
  {
    link: "https://japan.cnet.com/article/35135761/",
    src: "/asset/img/media/media_thumb_01.jpg",
    src2x: "/asset/img/media/media_thumb_01@2x.jpg",
    srcWebp: "/asset/img/media/media_thumb_01.jpg.webp",
    srcWebp2x: "/asset/img/media/media_thumb_01@2x.jpg.webp",
    alt: "飲食系アプリは装いが足りない--フード×テックを実践するクリスプ＆カチリ宮野氏",
    ttl: "CNET Japan",
    txt: "「飲食系アプリは装いが足りない--フード×テックを実践するクリスプ＆カチリ宮野氏",
  },
]

const Main = () => {
  return (
    <section className="p-press-main">
      <div className="p-press-main__wrapper">
        <ul className="p-press-main__list">
          {mainMedias.map(data => {
            return (
              <li className="p-press-main__item">
                <a
                  className="js-hover p-press-main__link"
                  data-hover-in-time="600"
                  href={data.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="js-scroll__dark c-link__img p-press-main__item-img">
                    {/* <img
                      className="c-img__def p-press-main__item-img-item"
                      src={data.src}
                      srcSet={`${data.src} 1x, ${data[src2x]} 2x`}
                      alt={data.alt}
                      loading="lazy"
                    /> */}
                    <Img
                      src={data.src}
                      src2x={data.src2x}
                      webpSrc={data.srcWebp}
                      webpSrc2x={data.srcWebp2x}
                      alt={data.alt}
                      imgClassName="p-press-main__item-img-item"
                    />
                  </div>
                  <div className="p-press-main__item-content">
                    <ArrowZ />
                    <h2 className="p-press-main__item-content-ttl">
                      {data.ttl}
                    </h2>
                    <p className="c-txt__main p-press-main__item-content-txt">
                      {data.txt}
                    </p>
                  </div>
                </a>
              </li>
            )
          })}
        </ul>
      </div>
    </section>
  )
}

export default Main
