import React from "react"

const Img = ({
  src,
  src2x,
  spSrc,
  webpSrc,
  webpSrc2x,
  webpSpSrc,
  alt,
  loading = "lazy",
  imgClassName,
}) => {
  return (
    <picture>
      {webpSpSrc && (
        <source
          media="(max-width:768px)"
          type="image/webp"
          srcSet={webpSpSrc}
        />
      )}
      {spSrc && <source media="(max-width:768px)" srcSet={spSrc} />}
      {webpSrc && (
        <source srcSet={`${webpSrc} 1x, ${webpSrc2x} 2x`} type="image/webp" />
      )}
      <img
        className={`c-img__def ${imgClassName}`}
        src={src}
        srcSet={`${src} 1x, ${src2x} 2x`}
        alt={alt}
        loading={loading}
      />
    </picture>
  )
}

export default Img
