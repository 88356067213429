import { Link } from "gatsby"
import React from "react"
import ArrowXWhite from "./ArrowXWhite"

const CareersLink = () => {
  // const redirectToCareer = () => {
  //   window.location.href = "/careers/"
  // }
  return (
    <section className="p-common-careers">
      <div
        className="js-scroll__dark js-hover p-common-careers__wrapper"
        data-hover-in-time="600"
      >
        <Link
          className="c-link__img p-common-careers__link"
          // onClick={redirectToCareer}
          to="/careers/"
        >
          <div className="p-common-careers__link-content">
            <h2 className="c-ttl__en p-common-careers__link-content-en">
              CAREERS
            </h2>
            <h3 className="c-ttl__main p-common-careers__link-content-ttl">
              CRISPだから、できる挑戦がある。
              <br className="u-none__sp" />
              外食を進化させるトップランナーへ。
            </h3>
            <p className="c-txt__main p-common-careers__link-content-txt">
              これからどんどん加速していく外食産業のDX。
              <br className="u-none__sp" />
              けれど今、それが本当の意味で実践できている企業はほとんどありません。DXを推し進め、新しいレストラン体験を創る。そのためなら、なんでも挑戦できるのがCRISPです。私たちの全員が、チャレンジャーであると同時に日本のトップランナーです。あなたの力で、私たちと一緒に新しい外食の未来をつくっていきましょう。
            </p>
            <ArrowXWhite />
          </div>
        </Link>
      </div>
    </section>
  )
}

export default CareersLink
