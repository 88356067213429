import React from "react"

const ArrowXWhite = ({ location, title, children }) => {
  return (
    <span className="c-icon__arrow">
    <span className="c-icon__arrow__origin c-icon__arrow__origin__x">
        <svg className="c-icon__arrow__def"
             width="16"
             height="15"
             viewBox="0 0 16 15"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M1 7.0481L15 7.0481"
                  stroke="#EAE8E3"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round" />
            <path d="M8.9541 13.0939L15.0002 7.04781L8.9541 1"
                  stroke="#EAE8E3"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round" />
        </svg>
    </span>
    <span className="c-icon__arrow__dammy c-icon__arrow__dammy__x">
        <svg className="c-icon__arrow__def"
             width="16"
             height="15"
             viewBox="0 0 16 15"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M1 7.0481L15 7.0481"
                  stroke="#EAE8E3"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round" />
            <path d="M8.9541 13.0939L15.0002 7.04781L8.9541 1"
                  stroke="#EAE8E3"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round" />
        </svg>
    </span>
</span>
)
}

export default ArrowXWhite